<template>
	<b-card-group>
		<b-card>
			<h4 class="mb-4">
				{{ translate('account_info') }}
			</h4>
			<is-loading
				:loading="loading"
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')" />
			<div class="table-responsive">
				<table
					v-show="!loading && Object.values(accountData).length > 0"
					class="table">
					<tbody>
						<general-setting-field
							v-for="item in Object.keys(accountData)"
							v-if="showField(item) && item !== 'qualified'"
							:key="item"
							:key-value="item"
							:field-information="checkFieldInformation(item)"
							:field-name="translate(item)"
							:errors="errors.errors[item]"
							:loading="loading"
							:options=" item === 'binary_placement_preference' ? binaryOptions : [] "
							@clearField="clearError(item)"
							@editField="editAccount($event, item)" />
					</tbody>
				</table>
			</div>
		</b-card>
	</b-card-group>
</template>
<script>

import GeneralSettingField from '@/views/Users/components/GeneralSettingsField';
import AccountInformation from '@/util/Profile';
import {
	DATE_KEYS as dateKeys, BOOLEAN_KEYS as booleanKeys, TRANSLATED_KEYS as translatedKeys, EDITABLE_FIELDS as editableFields,
	BINARY_PLACEMENT_OPTIONS as binaryOptions,
} from '@/settings/Profile';
import {
	Profile as AI, Validations, Users, Alerts, Ranks, Packages,
} from '@/translations';
import { MDY_FORMAT } from '@/settings/Dates';
import { customerRoles } from '@/settings/Roles';
import Alert from '@/util/Alert';
import EventBus from '@/util/eventBus';

export default {
	name: 'AccountInformation',
	messages: [AI, Validations, Users, Alerts, Ranks, Packages],
	components: { GeneralSettingField },
	data() {
		return {
			booleanKeys,
			dateKeys,
			accountInformation: new AccountInformation(),
			translatedKeys,
			editableFields,
			binaryOptions,
			alert: new Alert(),
		};
	},
	computed: {
		response() {
			try {
				const { data } = this.accountInformation.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		accountData() {
			try {
				return this.response.data.attributes;
			} catch (error) {
				return {};
			}
		},
		loading() {
			try {
				return this.accountInformation.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			try {
				return this.accountInformation.data.errors;
			} catch (error) {
				return [];
			}
		},
	},
	mounted() {
		if (customerRoles.indexOf(this.$user.details().type) !== -1) {
			this.accountInformation.getCustomerInformation(this.$user.details().id);
		} else {
			this.accountInformation.getInformation(this.$user.details().id);
		}
	},
	methods: {
		checkFieldInformation(item) {
			const fieldInformation = {
				code: item,
			};
			let dateValue = this.translate('null_date_label');
			fieldInformation.value = this.accountData[item];
			if (this.dateKeys.includes(item)) {
				if (fieldInformation.value !== null) {
					dateValue = this.$moment(this.accountData[item].date).format(MDY_FORMAT);
				}
				fieldInformation.value = dateValue;
			} else if (this.translatedKeys.includes(item)) {
				fieldInformation.value = this.translate(this.accountData[item]);
			} else if (this.booleanKeys.includes(item)) {
				fieldInformation.value = this.translate(`${!!this.accountData[item]}`);
			}
			fieldInformation.editable = this.editableFields.includes(item);
			return fieldInformation;
		},
		editAccount(fieldValue, id) {
			const formVal = {};
			formVal[id] = fieldValue;
			this.accountInformation.updateAccount(this.$user.details().id, formVal).then(() => {
				EventBus.$emit('updateAccountSteps');
				this.alert.toast('success', this.translate('account_updated'));
				this.accountInformation.getInformation(this.$user.details().id);
			});
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors.clear(field);
			}
		},
		showField(item) {
			return (this.accountData[item] || this.editableFields.includes(item) || this.dateKeys.includes(item));
		},
	},
};
</script>
